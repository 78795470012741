import React, { useCallback, useEffect, useState } from "react";
import Images from "../../assets/gen";
import KeywordCategory from "./component/keywordCategory";
import { BaseText } from "../../components";
import { BaseModal2 } from "../../components/modal/BaseModal2";
import { BaseInput } from "../../components/input/BaseInput";
import { BaseInputSelect } from "../../components/input/BaseInputSelect";
import { ThemaApi } from "../../apis/themaApi";
import { CategoryApi } from "../../apis/categoryApi";
import { TagApi } from "../../apis/tagApi";
import { KeywordTypeApi } from "../../apis/keywordTypeApi";
import {
  KeywordType,
  KeywordCategory as KeywordCategoryInterface,
} from "../../entities";
import { App } from "antd";
import { KeywordCategoryApi } from "../../apis/keywordCategoryApi";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Carousel } from "../../components/Carousel";

const KeywordSetting = () => {
  const { message } = App.useApp();
  const [openModalCreateKeywordType, setOpenModalCreateKeywordType] =
    useState(false);
  const [openModalCreateKeywordCategory, setOpenModalCreateKeywordCategory] =
    useState(false);
  const [nameKeywordType, setNameKeywordType] = useState("");
  const [thema_id, setThema_id] = useState<string>();
  const [category_id, setCategory_id] = useState<string>();
  const [listThema, setListThema] = useState<any>([]);
  const [listCategory, setListCategory] = useState<any>([]);
  const [listKeywordType, setListKeywordType] = useState<KeywordType[]>([]);
  const [listKeywordCategory, setListKeywordCategory] = useState<
    KeywordCategoryInterface[]
  >([]);
  const [keywordTypeId, setKeywordTypeId] = useState<string | undefined>(
    undefined,
  );
  const [nameKeywordCategory, setNameKeywordCategory] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [idEdit, setIdEdit] = useState<string | undefined>(undefined);

  //keywordType
  const handleCreateKeywordType = async () => {
    if (nameKeywordType && category_id) {
      try {
        const data = {
          name: nameKeywordType,
          category_id: category_id,
        };
        await KeywordTypeApi.createKeywordType(data);
        await getListKeywordType();
        message.success("Create successfully");
      } catch (e) {
        message.error("Create failed");
      }
    }
  };
  const handleUpdateKeywordType = async (id: string, data: KeywordType) => {
    try {
      await KeywordTypeApi.updateKeywordType(id, data);
      await getListKeywordType();
      message.success("Update successfully");
    } catch (e) {
      message.error("Update failed");
    }
  };
  const handleDeleteKeywordType = async (id: string) => {
    try {
      await KeywordTypeApi.deleteKeywordType(id);
      await getListKeywordType();
      message.success("Delete successfully");
    } catch (e) {
      message.error("Delete failed");
    }
  };
  const getListKeywordType = async () => {
    try {
      const res = await KeywordTypeApi.getList();
      setListKeywordType(res);
      if (res.length > 0 && !keywordTypeId) {
        setKeywordTypeId(res[0].id);
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  //keywordCategory
  const getListKeywordCategory = useCallback(async () => {
    try {
      if (keywordTypeId) {
        const res = await KeywordCategoryApi.getList({
          filter: JSON.stringify({ keyword_type_id: keywordTypeId }),
        });
        setListKeywordCategory(res);
      }
    } catch (e) {
      console.log("error", e);
    }
  }, [keywordTypeId]);
  const setStatusModalKeywordCategory = useCallback((value: boolean) => {
    setOpenModalCreateKeywordCategory(value);
  }, []);
  const changeKeywordCategory = useCallback((value: string) => {
    setNameKeywordCategory(value);
  }, []);
  //useEffect
  useEffect(() => {
    ThemaApi.getList()
      .then((res) => {
        const transformedDataListThema = res.map((item: any) => ({
          value: item.id,
          label: item.name,
        }));
        setListThema(transformedDataListThema);
      })
      .catch((err) => {});
    getListKeywordType();
  }, []);
  useEffect(() => {
    if (thema_id) {
      Promise.all([
        CategoryApi.getList({
          filter: `{"thema_id":"${thema_id}"}`,
        }),
      ])
        .then((res) => {
          // set data
          const transformedDataListCategory = res[0].map((item: any) => ({
            value: item.id,
            label: item.name,
          }));
          setListCategory(transformedDataListCategory);
        })
        .catch((err) => {
          // handle error
          console.log("error", err);
        });
    }
  }, [thema_id]);
  useEffect(() => {
    getListKeywordCategory();
  }, [keywordTypeId]);
  //modal
  const modalCreateKeywordType = () => {
    return (
      <BaseModal2
        isOpen={!!openModalCreateKeywordType}
        onClose={() => {
          setOpenModalCreateKeywordType(false);
          setIsEdit(false);
          setThema_id(undefined);
          setCategory_id(undefined);
          setNameKeywordType("");
          setIdEdit(undefined);
        }}
        onSubmit={() => {
          if (isEdit && idEdit) {
            handleUpdateKeywordType(idEdit!, {
              name: nameKeywordType,
              category_id: category_id!,
            });
          } else {
            handleCreateKeywordType();
          }
          setIsEdit(false);
          setThema_id(undefined);
          setCategory_id(undefined);
          setNameKeywordType("");
          setIdEdit(undefined);
          setOpenModalCreateKeywordType(false);
        }}
      >
        <div className="flex flex-col p-6 gap-6">
          <BaseInput
            title="Name"
            placeholder="Please enter name"
            value={nameKeywordType}
            onChange={(value) => setNameKeywordType(value)}
          />
          <BaseInputSelect
            title="Theme"
            options={listThema}
            placeholder="Select"
            onChange={(value) => setThema_id(value)}
            defaultValue={thema_id ? thema_id : undefined}
            value={thema_id}
          />
          <BaseInputSelect
            title="카테고리"
            options={listCategory}
            placeholder="카테고리를 선택해주세요"
            defaultValue={category_id ? category_id : undefined}
            value={category_id ? category_id : undefined}
            onChange={(value) => setCategory_id(value)}
          />
        </div>
      </BaseModal2>
    );
  };
  return (
    <div className="px-6">
      <div className="pt-4">
        <Carousel dragFree>
          <div className="flex gap-6 items-center">
            {listKeywordType.map((item, index) => (
              <div
                key={index}
                className="cursor-pointer group relative flex-shrink-0"
                onClick={() => setKeywordTypeId(item.id)}
              >
                <span className="inline-block font-bold py-6 text-xl text-[#141414]">
                  {item.name}
                </span>
                {item.id === keywordTypeId && (
                  <div className="h-1 bg-[#0866FF] rounded-t-3xl inset-0 z-0"></div>
                )}
                <div className="hidden group-hover:block absolute group-hover:top-0 group-hover:right-0">
                  <DeleteFilled
                    className="w-5 h-5"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteKeywordType(item.id!).then();
                    }}
                  />
                </div>
                <div className="hidden group-hover:block absolute group-hover:top-0 group-hover:right-6">
                  <EditFilled
                    className="w-5 h-5"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIdEdit(item.id);
                      setIsEdit(true);
                      setThema_id(item?.category?.thema_id);
                      setCategory_id(item?.category_id);
                      setNameKeywordType(item.name);
                      setOpenModalCreateKeywordType(true);
                    }}
                  />
                </div>
              </div>
            ))}
            <img
              src={Images.plus}
              className="w-10 h-10 cursor-pointer"
              onClick={() => setOpenModalCreateKeywordType(true)}
            />
          </div>
        </Carousel>
      </div>
      <div className="py-6">
        <div className="bg-[#F6F6F6] p-6 rounded-xl">
          {listKeywordCategory.length > 0 &&
            listKeywordCategory.map((item, index) => (
              <KeywordCategory
                key={index}
                category={item}
                keywordTypeId={keywordTypeId}
                getListKeywordCategory={getListKeywordCategory}
                nextIndex={
                  listKeywordCategory[index + 1]
                    ? listKeywordCategory[index + 1].index
                    : undefined
                }
                prevIndex={
                  listKeywordCategory[index - 1]
                    ? listKeywordCategory[index - 1].index
                    : undefined
                }
                next2Index={
                  listKeywordCategory[index + 2]
                    ? listKeywordCategory[index + 2].index
                    : undefined
                }
                prev2Index={
                  listKeywordCategory[index - 2]
                    ? listKeywordCategory[index - 2].index
                    : undefined
                }
                openModalCreateKeywordCategory={openModalCreateKeywordCategory}
                setOpenModalCreateKeywordCategory={
                  setStatusModalKeywordCategory
                }
                changeKeywordCategory={changeKeywordCategory}
                nameKeywordCategory={nameKeywordCategory}
              />
            ))}
          {listKeywordCategory.length === 0 && (
            <KeywordCategory
              category={{} as unknown as KeywordCategoryInterface}
              keywordTypeId={keywordTypeId}
              getListKeywordCategory={getListKeywordCategory}
              nextIndex={undefined}
              prevIndex={undefined}
              next2Index={undefined}
              prev2Index={undefined}
              openModalCreateKeywordCategory={openModalCreateKeywordCategory}
              setOpenModalCreateKeywordCategory={setStatusModalKeywordCategory}
              changeKeywordCategory={changeKeywordCategory}
              nameKeywordCategory={nameKeywordCategory}
            />
          )}
          <div onClick={() => setStatusModalKeywordCategory(true)}>
            <BaseText
              locale
              size={16}
              bold
              className="text-primary text-center block mx-auto w-full mt-[100px] cursor-pointer"
            >
              + Create one more
            </BaseText>
          </div>
        </div>
      </div>
      {modalCreateKeywordType()}
    </div>
  );
};

export default KeywordSetting;
