import { KeywordCategory } from "../entities";
import axiosClient from "./axiosClient";
import { KEYWORD_CATEGORY } from "./urlConfig";

export const KeywordCategoryApi = {
  getList: async (params?: object) => {
    const res: any = await axiosClient.get(`${KEYWORD_CATEGORY}/?fields=["$all",{"province":["$all"]},{"district":["$all"]}]&limit=50&order=[["index","ASC"],["created_at","DESC"]]`, { params });
    return res?.results?.objects?.rows || []
  },
  updateKeywordCategory: async (id: string, data: KeywordCategory) => {
    return await axiosClient.put(`${KEYWORD_CATEGORY}/${id}`, data);
  },
  deleteKeywordCategory: async (id: string) => {
    return await axiosClient.delete(`${KEYWORD_CATEGORY}/${id}`);
  },
  createKeywordCategory: async (data: KeywordCategory) => {
    return await axiosClient.post(KEYWORD_CATEGORY, data);
  },
  orderKeywordCategory: async (id?: string, data?: any) => {
    return await axiosClient.put(`${KEYWORD_CATEGORY}/order-keyword-category/${id}`, data);
  },
};
